var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){_vm.permSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.permSnackbar),callback:function ($$v) {_vm.permSnackbar=$$v},expression:"permSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',[_vm._v("I miei container ("+_vm._s(_vm.stacks.length ? _vm.stacks.length :"0")+")")])],1)],1),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.stacks,"item-key":"NOME_STACK","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Containers per pagina',
          itemsPerPageAllText: 'Tutti i containers',
          itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
          pageText: '',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Container per pagina'","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun Container ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun Container ")])]},proxy:true},{key:"item.Status",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.Status == '1' ? 'success' : 'error',"dark":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.Status == '1' ? 'mdi-check' : 'mdi-close'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Status == '1' ? 'Attivo' : 'Disattivato'))])])]}},{key:"item.EndService",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.EndService)+" ")]}},{key:"item.Owner",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Owner)+" ")]}},{key:"item.Description",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Description)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }